import React, { useEffect } from 'react';
import { t } from '@superset-ui/core';
import { VianaFrameComponentProps } from 'src/explore/components/controls/DateFilterControl/types';
import { DatePicker } from 'src/components/DatePicker';
import moment from 'moment';

export function VianaSamdtFrame(props: VianaFrameComponentProps) {
  // Can not select today and future
  // const disabledDate: any = (current: any) =>
  //   current && current >= moment().subtract(1, 'day');

  const onChange = (date: any, dateString: string) => {
    if (dateString !== '') {
      props.onChange(
        `${dateString} : ${moment(dateString)
          .add(1, 'days')
          .format('YYYY-MM-DD')}`,
      );

      props.onSetLabel(
        `${dateString} : ${moment(dateString)
          .add(1, 'days')
          .format('YYYY-MM-DD')}`,
      );
    }
  };

  useEffect(() => {}, [props]);

  return (
    <>
      <div className="section-title">{t('Custom ')}</div>
      <DatePicker
        onChange={onChange}
        // disabledDate={disabledDate}
        dropdownClassName="samdt-custom-filter"
      />
    </>
  );
}
